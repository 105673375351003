@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");
@font-face {
  font-family: Valencia;
  src: url(../Fonts/Valencia.ttf);
}

:root {
  --primary-color: #08eca9;
  --primary-black: #000000;
  --primary-white: #ecf0f3;
  --primary-light: #f4f5f6;
  --primary-font: #333;
  --font-light: #959595;
  --white: #ffffff;
  --highlight: #f4f9d7;
  --gradient: linear-gradient(145deg, #e2e8ec, #ffffff);
  --shadow: 5px 5px 15px #d1d9e6, -5px -5px 15px #ffffff;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  background-color: var(--primary-white);
  color: var(--primary-font);
}

/* Code for Firefox */
::-moz-selection {
  color: var(--primary-color);
  background: var(--white);
}
::selection {
  color: var(--primary-color);
  background: var(--white);
}

a {
  color: var(--primary-font);
}
a:hover {
  color: var(--primary-black);
  text-decoration: none;
}
h1 {
  font-family: Valencia;
}
h3 {
  font-size: 1.3rem;
  font-weight: 600;
}
hr {
  border-top: 1px solid rgb(8 236 169 / 20%);
  margin-top: 5rem;
  margin-bottom: 5rem;
}
p {
  text-align: justify;
}

.mybtn {
  display: block;
  text-align: left;
  background-color: var(--primary-light);
  box-shadow: 5px 5px 15px #d1d9e6, -5px -5px 15px #ffffff;
  background: linear-gradient(145deg, #e2e8ec, #ffffff);
  border-radius: 5px;
  transition: all 0.3s ease;
}
.mybtn:hover {
  box-shadow: none;
  transition: all 0.3s ease;
  background: linear-gradient(145deg, #e2e8ec, var(--primary-color));
}

.v-middle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  flex-wrap: wrap;
}

/********************************************************* Navbar *************************************************************/
.bottom-nav {
}
.fix-nav {
  box-shadow: 5px 5px 15px #d1d9e6, -5px -5px 15px #ffffff;
  background: linear-gradient(145deg, #e2e8ec, rgb(255 255 255 / 25%));
  /* width: max-content; */
  display: block;
  padding: 0.7rem 1.7rem;
  z-index: 10;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2rem;
  display: flex;
  gap: 0.35rem;
  border-radius: 3rem;
  backdrop-filter: blur(12px);
}
.fix-nav li {
  margin: 0px 5px;
}
.fix-nav a {
  color: var(--primary-font) !important;
  font-size: 1.2rem;
  border-radius: 50%;
  width: 49px;
  height: 47px;
  text-align: center;
  margin-left: 0px;
  transition-duration: 1s;
}
.fix-nav a:hover {
  background-color: rgb(112, 112, 112);
  color: var(--white) !important;
}
.fix-nav a.active {
  background-color: var(--primary-color);
  color: var(--primary-black) !important;
  transition-duration: 1s;
}
.fix-nav a i {
  margin-left: -1px;
}
header {
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
}
.navbar {
  width: 100%;
  padding: 30px 50px;
}
.nav-link {
  color: var(--primary-font) !important;
  font-weight: 600;
  margin-left: 15px;
}
.navbar-toggler {
  background-color: var(--primary-color);
}
.navbar-brand {
  margin-bottom: -5px;
}
#collapsibleNavbar {
  margin-left: auto;
}
.navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row;
}
.navbar .bi {
  font-size: 1.2rem;
}
.navbar .bi:hover {
  color: var(--primary-color);
}
.home {
  width: 100%;
  height: 100vh;
  text-align: center;
  position: relative;
}
.myname::after {
  content: "";
  height: 100%;
  width: 100%;
  background-image: url(../Images/logo-bg.png);
  position: absolute;
  background-repeat: no-repeat;
  background-position: right;
  background-size: auto;
  top: 0px;
  right: 0px;
  z-index: -1;
}
.myname {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: -1;
}
.myname h1 {
  font-size: 14rem;
  margin-bottom: 0px;
}
.myname .home-text span {
  font-size: 1.5rem;
  text-transform: uppercase;
  display: block;
  margin-top: -15px;
  margin-bottom: 30px;
  letter-spacing: 5px;
}
/* .mailme {
  position: absolute;
  bottom: 50px;
}
.mailme a {
  font-size: 20px;
  width: 320px;
}
.mailme .bi-envelope {
  color: var(--primary-font);
  margin: 13px 15px 15px 17px;
}
.mailme span {
  display: inline-block;
  padding: 15px;
  border-left: 2px solid var(--primary-color);
} */
.cv-btn {
  margin-left: 20px;
  padding: 10px 16px !important;
}

/****************************************************work************************************************************/

.heading {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 10px;
  position: relative;
}
.heading h2 {
  text-transform: uppercase;
  font-size: 6rem;
  font-weight: 800;
  letter-spacing: 10px;
  color: var(--white);
}
.heading span {
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  width: 100%;
  font-size: 30px;
  font-weight: 800;
  text-align: -webkit-center;
}
.heading span hr {
  width: 100px;
  margin-top: 0px;
  margin-bottom: 0px;
  border-top: 4px solid var(--primary-color);
}
.grids {
  display: flex;
  flex-wrap: wrap;
  margin: 90px -60px 0 0;
}
.work {
  display: inline-block;
  width: calc(33.33% - 60px);
  margin-right: 60px;
  margin-bottom: 60px;
}
.work .img-box {
  width: 100%;
  height: 300px;
  background: linear-gradient(
    45deg,
    transparent,
    rgb(8 236 169 / 20%),
    transparent
  );
  border-radius: 15px;
}
.img-box .img {
  width: 100%;
  height: 300px;
  overflow: hidden;
  transform: rotate(6deg);
  border-radius: 15px;
}
.img-box .img img {
  width: 100%;
}
.work:nth-child(3n + 2) {
  margin-top: -55px;
}
.work span {
  text-transform: uppercase;
  font-size: 12px;
  margin-top: 20px;
  display: block;
  color: var(--font-light);
  letter-spacing: 2px;
}
.work:hover .img-box .img {
  transform: rotate(0deg);
  transition-duration: 200ms;
}

/****************************************************about************************************************************/

.about {
  margin-top: 50px;
  min-height: 55vh;
}
.v-middle .heading {
  width: 100%;
  margin-top: 0px;
}
.about h2 {
  font-weight: 600;
  margin-bottom: 20px;
}
.my-info span {
  background-color: var(--highlight);
}
/*timeline*/
.timeline-holder {
  margin: auto;
}
.timeline-head {
  padding-bottom: 20px;
  width: 100%;
  position: relative;
  text-align: center;
}
.timeline-head:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 4px;
  height: 100%;
  background-color: #272425;
  top: 103px;
}
.timeline-head .icon {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border-radius: 500px;
  border: 3px solid var(--white);
  color: #272425;
  display: inline-block;
  width: 54px;
  height: 54px;
  position: relative;
}
.timeline-head .icon i {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 500px;
  border: 2px solid var(--primary-color);
  width: 80%;
  height: 80%;
}
.timeline-head .icon i:before {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 500px;
  vertical-align: middle;
  text-align: center;
  font-size: 22px;
  width: 22px;
  height: 22px;
  line-height: 22px;
  color: var(--primary-font);
}
.timeline-body {
  width: 100%;
  margin-bottom: 10px;
}
.event-item {
  padding-bottom: 60px;
}
.timeline-item {
  position: relative;
  width: 100%;
  display: flex !important;
}
.timeline-item:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 40px;
  width: 4px;
  height: 100%;
  bottom: auto;
  background-color: #272425;
}
.event-title.left {
  text-align: right;
  padding-right: 45px;
}
.event-title {
  letter-spacing: -1px;
  width: 55%;
  display: inline-block;
  vertical-align: top;
}
.event-title:before {
  content: "⚫";
  text-align: center;
  font-size: 20px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  z-index: 2;
  border-radius: 100px;
  position: absolute;
  left: 0;
  right: 3px;
  margin: auto;
  top: 0px;
  bottom: auto;
}
.event-content.right {
  padding-left: 25px;
}
.event-title.right {
  padding-left: 41px;
}
.event-content.left p {
  text-align: right;
}
.event-content {
  padding: 0 16px;
  font-size: 14px;
  line-height: 23px;
  width: 45%;
}
.timeline-content {
  position: relative;
  display: inline-block;
}
.timeline-content.right:before {
  content: "{";
  font-family: "Open Sans", sans-serif;
  position: absolute;
  font-size: 90px;
  line-height: 90px;
  font-weight: 100;
  left: -18px;
  top: -14px;
}
.timeline-item:after {
  content: "⚫";
  font-family: fontawesome;
  text-align: center;
  font-size: 15px;
  width: 15px;
  height: 15px;
  line-height: 15px;
  position: absolute;
  left: -6px;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  top: 32px;
  bottom: auto;
}
.event-title h1 {
  font-weight: 800 !important;
  font-size: 20px;
  line-height: 32px;
  color: #31708f;
}
.timeline-content h4 {
  font-size: 16px;
  font-weight: 600;
  margin-top: 15px;
}
.event-content.timeline-content.left {
  text-align: right;
  padding-top: 15px;
  padding-right: 10px;
}
.timeline-content.left:before {
  content: "}";
  font-family: "Open Sans", sans-serif;
  position: absolute;
  font-size: 90px;
  line-height: 90px;
  font-weight: 100;
  right: -18px;
  top: -14px;
}
.circular-icon {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border-radius: 500px;
  border: 3px solid var(--white);
  color: #272425;
  display: inline-block;
  width: 40px;
  height: 40px;
  position: relative;
}
.circular-icon i {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 500px;
  border: 1px solid var(--primary-color);
  width: 80%;
  height: 80%;
}
.circular-icon i:before {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 500px;
  vertical-align: middle;
  text-align: center;
  font-size: 16px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  color: var(--primary-black);
}
.profile-item .profile-content {
  width: 45%;
  min-height: 65px;
  padding: 10px;
}
.profile-item label {
  display: inline-block;
  margin-bottom: 0.3rem;
  text-transform: uppercase;
  font-size: 13px;
}
.icon-item.left {
  letter-spacing: -1px;
  width: 55%;
  display: inline-block;
  vertical-align: middle;
  text-align: right;
  padding-right: 40px;
  float: left;
  margin-top: 20px;
}
.icon-item.right {
  margin-top: 20px;
  text-align: left;
  margin-right: -5%;
  letter-spacing: -1px;
  width: 55%;
  display: inline-block;
  vertical-align: middle;
  float: left;
  padding-left: 40px;
}
.event-content.timeline-content.left h4 {
  margin-top: 0px;
}
.profile-item {
  margin-bottom: 5px;
}
.profile-item .value {
  font-weight: 600;
  font-size: 15px;
  display: block !important;
  word-break: break-all;
}
.timeline-holder {
  width: 100%;
}
.event-title h2 {
  font-size: 18px;
}
/*timeline ends*/

/****************************************************skills************************************************************/
.certificate {
  margin: 30px 55px 70px 0px;
}
.certificate a {
  display: inline-block;
  width: 100%;
  background: linear-gradient(
    340deg,
    transparent,
    rgb(8 236 169 / 20%),
    transparent
  );
  border-radius: 15px;
}
.certificate img {
  width: 100%;
  transform: rotate(-6deg);
  border-radius: 15px;
  display: inline-block;
}
.certificate a:hover img {
  transform: rotate(0deg);
  transition-duration: 500ms;
}
.my-info {
  margin-bottom: 30px;
}
.skill-icons {
  display: flex;
  flex-wrap: wrap;
  margin: 10px -20px 20px 0px;
}
.skill-icons .icons {
  display: inline-block;
  text-align: center;
  background-color: var(--primary-light);
  box-shadow: var(--shadow);
  background: var(--gradient);
  border-radius: 10px;
  transition: all 0.3s ease;
  padding: 15px;
  width: calc(20% - 20px);
  margin: 0px 20px 20px 0px;
}
.skill-icons .icons:hover {
  box-shadow: inset -2px -1px 10px rgb(255 255 255),
    inset 3px 5px 7px rgb(0 0 0 / 22%);
}
.skill-icons .icons img {
  height: 65px;
}
#skill .timeline-item:before {
  content: "";
  top: -20px;
}

/****************************************************Contact************************************************************/
.contact {
  margin-top: 50px;
  margin-bottom: 50px;
}
form {
  margin-top: 50px;
}
.input-effect {
  position: relative;
  width: 100%;
}
.txt-box {
  border: 0;
  padding: 4px 0;
  border-bottom: 1px solid #ccc;
  background-color: transparent;
  width: 100%;
  border-radius: 0px;
}
.txt-box ~ .focus-border {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: var(--primary-color);
  transition: 0.4s;
  background: linear-gradient(45deg, #43f3bf, #089d71);
}
.txt-box:focus ~ .focus-border,
.has-content.txt-box ~ .focus-border {
  width: 100%;
  transition: 0.4s;
}
.txt-box ~ label {
  position: absolute;
  left: 0;
  width: 100%;
  top: -16px;
  color: #aaa;
  transition: 0.3s;
  z-index: -1;
  letter-spacing: 0.5px;
}
.txt-box:focus ~ label,
.txt-box:active ~ label,
.has-content.txt-box ~ label {
  top: -16px;
  font-size: 12px;
  color: var(--primary-color);
  transition: 0.3s;
}
.msg-box {
  font-size: 1.2rem;
  margin-bottom: -5px;
  border: 1px solid #ccc;
  padding: 7px 14px;
  transition: 0.4s;
  background: transparent;
  height: 130px;
  resize: none;
  width: 100%;
  border-radius: 0px;
}
.msg-box ~ .focus-border:before,
.msg-box ~ .focus-border:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 2px;
  transition: 0.2s;
  transition-delay: 0.2s;
  background-color: var(--primary-color);
  background: linear-gradient(45deg, #43f3bf, #089d71);
}
.msg-box ~ .focus-border:after {
  top: auto;
  bottom: 0;
  right: auto;
  left: 0;
  transition-delay: 0.6s;
}
.msg-box ~ .focus-border i:before,
.msg-box ~ .focus-border i:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 0;
  background-color: #089d71;
  transition: 0.2s;
}
.msg-box ~ .focus-border i:before {
  background-color: var(--primary-color);
}
.msg-box ~ .focus-border i:after {
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
  transition-delay: 0.4s;
}
.msg-box:focus ~ .focus-border:before,
.msg-box:focus ~ .focus-border:after,
.has-content.msg-box ~ .focus-border:before,
.has-content.msg-box ~ .focus-border:after {
  width: 100%;
  transition: 0.2s;
  transition-delay: 0.6s;
}
.msg-box:focus ~ .focus-border:after,
.has-content.msg-box ~ .focus-border:after {
  transition-delay: 0.2s;
}
.msg-box:focus ~ .focus-border i:before,
.msg-box:focus ~ .focus-border i:after,
.has-content.msg-box ~ .focus-border i:before,
.has-content.msg-box ~ .focus-border i:after {
  height: 100%;
  transition: 0.2s;
}
.msg-box:focus ~ .focus-border i:after,
.has-conten.msg-box ~ .focus-border i:after {
  transition-delay: 0.4s;
}
.msg-box ~ label {
  position: absolute;
  width: 100%;
  top: -24px;
  left: 0;
  color: #aaa;
  transition: 0.3s;
  z-index: -1;
  letter-spacing: 0.5px;
}
.msg-box:focus ~ label,
.has-content.msg-box ~ label {
  top: -20px;
  left: 0;
  font-size: 12px;
  color: var(--primary-color);
  transition: 0.3s;
}
.form-group {
  margin-top: 2rem;
}
.form-group:nth-child(3) {
  margin-top: 3rem;
}
form .mybtn {
  width: 100%;
  text-align: center;
  height: 50px;
  margin-top: 20px;
  margin-bottom: 30px;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--primary-black);
}

.date-div {
  position: relative;
  display: inline-block;
  width: 260px;
  height: 240px;
  padding: 30px;
  padding-top: 10px;
}
.date {
  border: 1px dashed var(--primary-color);
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;
  border-radius: 50%;
  padding-top: 55px;
}
.date span {
  font-size: 2.5rem;
  font-weight: 700;
  letter-spacing: 1px;
}
.date-div p {
  position: absolute;
  left: 0px;
  bottom: 40px;
  width: 100%;
  text-align: center;
  font-size: 1.3rem;
  color: #fff;
  background-color: var(--primary-color);
  padding: 3px 0px 7px 0px;
  transform: rotate(353deg);
}
.social {
  display: flex;
  margin-bottom: 50px;
}
.social a {
  width: 50%;
  display: inline-block;
  text-align: center;
  background-color: var(--primary-light);
  box-shadow: var(--shadow);
  background: var(--gradient);
  border-radius: 10px;
  transition: all 0.3s ease;
  padding: 15px;
  margin: 15px;
}
.social a:hover {
  box-shadow: inset -2px -1px 10px rgb(255 255 255),
    inset 3px 5px 7px rgb(0 0 0 / 22%);
}
.social h4 {
  font-size: 1.3rem;
}
.social svg {
  color: #4267b2;
  margin-top: 4px;
  margin-bottom: 4px;
}
.social i {
  color: #128c7e;
  font-size: 1.6rem;
}

footer {
  text-align: center;
  padding: 15px 0px;
  margin-top: 50px;
  margin-bottom: 115px;
  letter-spacing: 1px;
}
footer span {
  color: #f33;
  font-size: 15px;
  animation: beat 0.5s infinite alternate;
  transform-origin: center;
  display: inline-block;
  margin-left: 3px;
}
@keyframes beat {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}






/******************************* custom cursor **********************************************/
#root, .fixdiv{
  cursor:none;
}
.fixdiv{
  width: 100%;
    height: 100vh;
    position: fixed;
}
.cursor{
  width:20px;
  height:20px;
  border-radius: 50%;
  position:absolute;
  transform: translate3d(-50%, -50%, 0);
  top:0;
  left:0;
  background: transparent;
  border: 2px solid var(--primary-color);
}
.cursor-lazy{
  width: 200px;
    height: 200px;
    border-radius: 50%;
    position: absolute;
    transform: translate3d(-50%, -50%, 0);
    top: 0;
    left: 0;
    background: linear-gradient(45deg, rgba(235, 14, 239, 0.212) 0%, rgba(4, 222, 233, 0.274) 100%);
    filter: blur(60px);
    transition: 0.1s ease-in-out;
    z-index: -2;
}