@media (max-width: 1080px) {
  .grids {
    margin: 90px -30px 0 0;
  }
  .work {
    width: calc(33.33% - 30px);
    margin-right: 30px;
  }
}

@media (max-width: 960px) {
  .work:nth-child(odd) {
    margin-top: -55px;
  }
  .work:nth-child(even) {
    margin-top: 0;
  }
  .work {
    width: calc(50% - 60px);
    margin-right: 60px;
  }
  .skill-icons .icons {
    width: calc(25% - 20px);
  }
  .skill-icons .icons img {
    height: 45px;
  }
}

@media (max-width: 767px) {
  #root {
    padding-left: 5px;
}
  .myname h1 {
    font-size: 8rem;
  }
  .myname .home-text span {
    font-size: 1rem;
  }
  .myname::after {
    top: 50px;
  }
  .heading h2 {
    font-size: 4rem;
  }
  #skill .row,
  .contact .row {
    flex-wrap: wrap-reverse;
  }
  .cursor{
    display: none;
  }
}

@media (max-width: 420px) {
  .navbar {
    width: 100%;
    padding: 15px 12px;
  }
  .navbar-brand img {
    width: 50px;
  }
  .fix-nav {
    bottom: 1rem;
  }
  .myname h1 {
    font-size: 5.6rem;
  }
  .myname .home-text span {
    font-size: 0.8rem;
  }
  .myname .home-text span {
    margin-top: 6px;
    letter-spacing: 4px;
  }
  .myname::after {
    top: 50px;
    right: 20px;
    background-size: 70%;
  }
  .heading h2 {
    font-size: 2.7rem;
  }
  .heading span {
    font-size: 22px;
    letter-spacing: 2px;
  }
  .grids {
    margin: 90px 5px 0 5px;
  }
  .work {
    width: calc(100% - 0px);
    margin-right: 15px;
  }
  .work:nth-child(odd) {
    margin-top: 0px;
  }
  .event-content.timeline-content.left {
    padding-left: 0px;
  }
  .fix-nav li {
    margin: 0px 2px;
  }
  .skill-icons .icons {
    width: calc(33.33% - 20px);
  }
  .certificate {
    margin: 30px 20px 70px 20px;
  }
  .skill-icons .icons img {
    height: 60px;
  }
}
